import React, { useState, useEffect } from "react";
import { Layout as LayoutAntd, Breadcrumb, ConfigProvider, notification } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";
import Header from "./Header";
import SiderMenu from "./SiderMenu";
import Footer from "./Footer";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Outlet } from "react-router-dom";
import locale from "antd/lib/locale/es_ES";
import useAuth from "../../hooks/useAuth";
import FirstTimeChangePassword from "../auth/FirstTimeChangePassword";

const Layout = () => {
  const { Content } = LayoutAntd;
  const { user } = useAuth();
  let url = window.location.pathname;
  let result = url.split("/");
  const isMobile = useMediaQuery("max-width", 790);
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(0);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const resultParched = result[1];

  const translations = {
    "ot": "Ordenes de Trabajo",
    "ventas": "Ventas",
    "repuestos": "Repuestos",
    "stock": "Stock",
    "estado-cargas": "Estado de Cargas",
    "ventas-charts": "Gráfico Ventas",
    "ot-charts": "Gráfico OTs",
    "ot-osat-report": "OT Reporte Osat",
    "repuestos-charts": "Gráfico Repuestos",
    "file-upload": "Carga Manual",
    "conexion-usuarios": "Conexión de Usuarios",
    "users": "Usuarios",
    "profiles": "Perfiles",
    "brands": "Marcas",
    "dealers": "Concesionarios",
    "notifications": "Notificaciones",
    "updates": "Actualizaciones",
    "all-notifications": "Notificaciones de Cargas Pendientes"
  }

  const translation = translations[resultParched.toLowerCase()] || resultParched;

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setCollapsed(true);
      } else {
        setCollapsed(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (user.first_time) {
      setShowModal(true);
    }
  }, [user]);

  useEffect(() => {
    if (successMessage === 1) {
      notification.success({
        message: "La contraseña ha sido cambiada exitosamente",
        placement: 'topRight',
        duration: 3,
        onClose: () => setSuccessMessage(0),
      });
    }
    else if (successMessage === 2) {
      notification.success({
        message: "Algo salió mal",
        placement: 'topRight',
        duration: 3,
        onClose: () => setSuccessMessage(0),
        style: {
          borderRadius: '8px',
          border: '1px solid #f5222d',
        },
      });
    }
  }, [successMessage]);

  const toggleSidebar = () => setCollapsed(!collapsed);

  return (
    <LayoutAntd className="layout">
      <Header />
      <LayoutAntd>
        <SiderMenu
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          isMobile={isMobile}
        />
        <LayoutAntd style={{
          marginLeft: collapsed ? 0 : 0,
          padding: isMobile ? "0 12px 12px" : "0 24px 24px",
          minHeight: "calc(100vh - 70px)",
          transition: "all 0.3s ease"
        }}>
          <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
            <div onClick={toggleSidebar} style={{ cursor: 'pointer', marginRight: '10px' }}>
              {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </div>
            <Breadcrumb style={{
              margin: isMobile ? "8px 0" : "16px 0",
              padding: "0 8px"
            }}>
              <Breadcrumb.Item>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{translation.toUpperCase()}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Content
            className="site-layout-background"
            style={{
              padding: isMobile ? 12 : 24,
              margin: 0,
              minHeight: 444,
              background: "#fff",
              borderRadius: "4px"
            }}
          >
            <ConfigProvider locale={locale}>
              <Outlet />
            </ConfigProvider>
          </Content>
          {!isMobile && <Footer />}
        </LayoutAntd>
      </LayoutAntd>
      {showModal && <FirstTimeChangePassword onClose={handleCloseModal} setSuccessMessage={setSuccessMessage} />}
    </LayoutAntd>
  );
};

export default Layout;
