import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    loading: false,
    error: false,
    data: {
        typeUpdate: [],
        total: 0,
    }
};

const slice = createSlice({
    name: "typeUpdates",
    initialState,
    reducers: {
        startLoading(state) {
            state.loading = true;
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        typeUpdate(state, action) {
            state.loading = false;
            state.data = {
                ...action.payload,
                typeUpdate: action.payload
                    ? action.payload.map((x) => ({
                        ...x,
                        key: x.id
                    }))
                    : [],
                total: action.payload.length || 0,
            };
        },
        report(state) {
            state.loading = false;
            state.report = true;
        }
    }
});

export const { startLoading, hasError, typeUpdate, report } = slice.actions;

export function getTypeUpdates() {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            const response = await axios.get("/typeUpdates/all");
            dispatch(typeUpdate(response.data));
            return response.data;
        } catch (error) {
            dispatch(hasError(error.toString()));
            throw error;
        }
    };
}

export default slice.reducer;