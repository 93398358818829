import React from "react";
import "./ButtonUpdate.css";

export const Updates = ({ updateList }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const getBorderColor = (id_type_update) => {
    const typeId = parseInt(id_type_update, 10);
    switch (typeId) {
      case 1:
        return "border-interface";
      case 2:
        return "border-functional";
      case 3:
        return "border-scalability";
      case 4:
        return "border-support";
      default:
        return "border-functional";
    }
  };

  return (
    <div className="actualizations-container">
      <div className="updates-list">
        {updateList.map((update) => (
          <div
            className={`update-card ${getBorderColor(update?.id_type_update)}`}
            key={update.id_update}
          >
            <div className="update-header">
              <span className="update-type">{update?.typeUpdate?.type_name || ""}</span>
              <span className="update-date">{formatDate(update.create_at)}</span>
            </div>
            <div
              className="update-description"
              dangerouslySetInnerHTML={{ __html: update.description }}
            />
            <div className="update-version">Versión: {update?.version || ""}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
