import React, { useEffect, useState } from "react";
import { UpdateIcon } from "../updatesComponent/UpdateIcon";
import { Button, Tooltip, Modal } from "antd";
import "../updatesComponent/ButtonUpdate.css";
import { getUpdates } from "../../redux/slices/updates";
import { useDispatch, useSelector } from "react-redux";
import { Updates } from "../updatesComponent/Updates";
import useMediaQuery from "../../hooks/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import { updateFlagModal } from "../../redux/slices/users"

export const ButtonUpdates = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleFirst, setIsModalVisibleFirst] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { user, refreshInfoToken } = useAuth();

  const updateList = useSelector((state) => state.updates.data.update || []);
  const isMobile = useMediaQuery("max-width: 768px");

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancelModalFirst = () => {
    dispatch(updateFlagModal({ flag: false, refreshInfoToken }))
    setIsModalVisibleFirst(false)
  }

  useEffect(() => {
    dispatch(getUpdates());
  }, [dispatch]);

  useEffect(() => {
    setIsVisible(updateList.length > 0);
  }, [updateList]);

  useEffect(() => {
    if (user?.active_modal === true) {
      setIsModalVisibleFirst(true)
    }
  }, [])

  const title = (
    <div className="carousel-title">
      <div
        className="carousel-content"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          fontSize: "20px",
        }}
      >
        <UpdateIcon style={{ fill: "rgb(0,21,41)", marginRight: "10px" }} />
        Actualizaciones
      </div>
    </div>
  );

  return isVisible ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginRight: isMobile ? "8px" : "20px",
        marginTop: "8px",
      }}
    >
      <Tooltip
        title="Actualizaciones"
        placement="topLeft"
      >
        <Button
          onClick={showModal}
          className="animated-button"
          style={{
            backgroundColor: "transparent",
            border: 0,
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <UpdateIcon style={{ fill: "#FFFFFF", width: "24px", height: "24px" }} />
        </Button>
      </Tooltip>
      <Modal
        title={title}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={isMobile ? "90%" : "500px"}
        bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
        style={{ borderRadius: "12px" }}
        className="custom-modal"
      >
        <Updates updateList={updateList} />
      </Modal>

      {isModalVisibleFirst && (
        <Modal
          title={title}
          visible={isModalVisibleFirst}
          onCancel={handleCancelModalFirst}
          footer={null}
          width={isMobile ? "90%" : "500px"}
          bodyStyle={{ maxHeight: "500px", overflowY: "auto" }}
          style={{ borderRadius: "12px" }}
          className="custom-modal"
          maskClosable={false}
        >
          <Updates updateList={updateList} />
        </Modal>
      )}
    </div>
  ) : null;
};
