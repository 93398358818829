import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  filters_data: {},
  data: {
    users: [],
    status: "",
    message: "",
    user_preferences: "",
    flag_modal: "",
    user_preferences_ot: {},
    user_preferences_venta: {},
    user_preferences_repuestos: {},
    user_preferences_stock: {},
    tableSize: {},
    tableSizeVenta: {},
    tableSizeRepuestos: {},
    tableSizeStock: {}
  },
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    users(state, action) {
      state.loading = false;
      state.data = {
        ...action.payload,
        ...state.data,
        users: action.payload.users.map((x) => {
          return { ...x, key: x.id };
        }),
        total: action.payload.total,
      };
    },
    profiles(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        profiles: action.payload.profiles.map((x) => {
          return { ...x, key: x.id };
        }),
      };
    },
    profilePermissions(state, action) {
      state.loading = false;
      state.filters_data = {
        ...action.payload,
        ...state.filters_data,
        profilePermissions: action.payload.permissions.map((x) => {
          return { ...x, key: x.id }
        })
      };
    },
    saveUser(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        status: action.payload.data.status,
        message: action.payload.data.message,
      };
    },
    resetMessage(state, action) {
      state.data = {
        ...state.data,
        status: "",
        message: "",
      };
    },
    updateUserSuccess(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        status: action.payload.status,
        message: action.payload.message,
      };
    },
    userPreferences(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        user_preferences: action.payload.filter_preference,
      };
    },
    userFlagModal(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        status: action.payload.status,
        message: action.payload.message,
      };
    },
    updateFlagModal(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        flag_modal: action.payload.active_modal,
      };
    },
    updateUserPreferences(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        user_preferences: action.payload.filter_preference,
      };
    },
    userPreferencesOt(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        user_preferences_ot: action.payload,
      };
    },
    userPreferencesVenta(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        user_preferences_venta: action.payload,
      };
    },
    userPreferencesRepuestos(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        user_preferences_repuestos: action.payload,
      };
    },
    userPreferencesStock(state, action) {
      state.loading = false;
      state.data = {
        ...state.data,
        user_preferences_stock: action.payload,
      };
    },
    setTableSize(state, action) {
      state.loading = false;
      state.tableSize = action.payload;
    },
    setTableSizeVenta(state, action) {
      state.loading = false;
      state.tableSizeVenta = action.payload;
    },
    setTableSizeRepuestos(state, action) {
      state.loading = false;
      state.tableSizeRepuestos = action.payload;
    },
    setTableSizeStock(state, action) {
      state.loading = false;
      state.tableSizeStock = action.payload;
    },
    updatePreferencesOt(state, action) {
      state.preferencesOt = {
        ...state.preferencesOt,
        ...action.payload
      }
    },
    updatePreferencesVenta(state, action) {
      state.preferencesVenta = {
        ...state.preferencesVenta,
        ...action.payload
      }
    },
    updatePreferencesRepuestos(state, action) {
      state.preferencesRepuestos = {
        ...state.preferencesRepuestos,
        ...action.payload
      }
    },
    updatePreferencesStock(state, action) {
      state.preferencesStock = {
        ...state.preferencesStock,
        ...action.payload
      }
    },
    updateTableSize(state, action) {
      state.tableSize = {
        ...state.tableSize,
        ...action.payload,
      };
    },
    updateTableSizeVenta(state, action) {
      state.tableSizeVenta = {
        ...state.tableSizeVenta,
        ...action.payload,
      };
    },
    updateTableSizeRepuestos(state, action) {
      state.tableSizeRepuestos = {
        ...state.tableSizeRepuestos,
        ...action.payload,
      };
    },
    updateTableSizeStock(state, action) {
      state.tableSizeStock = {
        ...state.tableSizeStock,
        ...action.payload,
      };
    },
  },
});

export default slice.reducer;

export function getUsers({ filters = {} }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/all?`;
      let count = 0;
      for (const property in filters) {
        if (filters[property] !== null) {
          url +=
            count == 0
              ? `${property}=${filters[property]}`
              : `&${property}=${filters[property]}`;
          count++;
        }
      }
      const response = await axios.get(url);
      dispatch(slice.actions.users(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserById(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/details/${id}`;
      const response = await axios.get(url);
      dispatch(slice.actions.users({ users: [response.data], total: 1 }));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPreferences(setActiveKey) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/preference`;
      const response = await axios.get(url);
      dispatch(slice.actions.userPreferences(response.data));
      setActiveKey(response.data.filter_preference);
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfilesList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/profiles?`;
      const response = await axios.get(url);
      dispatch(slice.actions.profiles(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfilePermissionsList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/profilepermissions`;
      const response = await axios.get(url);
      dispatch(slice.actions.profilePermissions(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUser(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/save`;
      const response = await axios.post(url, {
        ...user,
        swagger_access: user.swagger_access || false,
      });
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
      if (response && response.data) {
        return Promise.resolve(response.data);
      }
      else {
        throw Promise.reject(new Error('Invalid response format'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUser(user) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/update`;
      const response = await axios.put(url, {
        ...user,
        swagger_access: user.swagger_access,
      });
      //dispatch(slice.actions.saveUser(response));
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
      if (response && response.data) {
        return Promise.resolve(response.data);
      }
      else {
        throw Promise.reject(new Error('Invalid response format'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updatePassAndName({ name, currentPassword, newPassword }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error("Token no encontrado");
      }
      const response = await axios.put(
        '/users/updatePassAndName',
        { name, currentPassword, newPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      dispatch(slice.actions.updateUserSuccess(response.data));
      setTimeout(() => {
        dispatch(slice.actions.resetMessage());
      }, 5000);
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error.message));
      return Promise.reject(error);
    }
  };
}

export function updateUserPreferences({ filter_preference }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/updatePreferences`;
      const response = await axios.put(url, { filter_preference });
      dispatch(slice.actions.updateUserPreferences(response.data));
      if (response && response.data) {
        return Promise.resolve(response.data);
      }
      else {
        throw Promise.reject(new Error('Invalid response format'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPreferencesOt() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/preferences/ot`;
      const response = await axios.get(url);
      dispatch(slice.actions.userPreferencesOt(response.data));
      dispatch(slice.actions.setTableSize(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPreferencesVenta() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/preferences/venta`;
      const response = await axios.get(url);
      dispatch(slice.actions.userPreferencesVenta(response.data));
      dispatch(slice.actions.setTableSizeVenta(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPreferencesRepuestos() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/preferences/repuestos`;
      const response = await axios.get(url);
      dispatch(slice.actions.userPreferencesRepuestos(response.data));
      dispatch(slice.actions.setTableSizeRepuestos(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPreferencesStock() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/preferences/stock`;
      const response = await axios.get(url);
      dispatch(slice.actions.userPreferencesStock(response.data));
      dispatch(slice.actions.setTableSizeStock(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserPreferencesOt({ ot_preferences, table_size }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/edit/preferences/ot`;
      const response = await axios.put(url, { ot_preferences, table_size });
      dispatch(slice.actions.updatePreferencesOt(response.data.preferencesOt));
      dispatch(slice.actions.updateTableSize(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserPreferencesVenta({ venta_preferences, table_size }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/edit/preferences/venta`;
      const response = await axios.put(url, { venta_preferences, table_size });
      dispatch(slice.actions.updatePreferencesVenta(response.data.preferencesVenta));
      dispatch(slice.actions.updateTableSizeVenta(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserPreferencesRepuestos({ repuestos_preferences, table_size }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/edit/preferences/repuestos`;
      const response = await axios.put(url, { repuestos_preferences, table_size });
      dispatch(slice.actions.updatePreferencesRepuestos(response.data.preferencesRepuestos));
      dispatch(slice.actions.updateTableSizeRepuestos(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUserPreferencesStock({ stock_preferences, table_size }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let url = `/users/edit/preferences/stock`;
      const response = await axios.put(url, { stock_preferences, table_size });
      dispatch(slice.actions.updatePreferencesStock(response.data.preferencesStock));
      dispatch(slice.actions.updateTableSizeStock(response.data.tableSize));
      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateFlagModal({ flag, refreshInfoToken }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/users/edit/activeModal`;
      const response = await axios.put(url, { flag });
      dispatch(slice.actions.updateFlagModal(response.data.active_modal));

      if (refreshInfoToken) {
        await refreshInfoToken();
      }

      return Promise.resolve(response.data);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return Promise.reject(error);
    }
  };
}
