import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
    loading: false,
    error: false,
    data: {
        update: [],
        total: 0,
    },
    dataAll: {
        updatesAll: [],
        total: 0,
    },
};

const slice = createSlice({
    name: "updates",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.loading = true;
        },
        updates(state, action) {
            state.loading = false;
            state.data = {
                ...action.payload,
                update: action.payload
                    ? action.payload.map((x) => ({
                        ...x,
                        key: x.id
                    }))
                    : [],
                total: action.payload.length || 0,
            };
        },
        allUpdates(state, action) {
            state.loading = false;
            state.dataAll = {
                ...action.payload,
                updatesAll: action.payload
                    ? action.payload.map((x) => ({
                        ...x,
                        key: x.id
                    }))
                    : [],
                total: action.payload.length || 0,
            };
        },
        hasError(state, action) {
            state.loading = false;
            state.error = action.payload?.message;
        },
        report(state) {
            state.loading = false;
            state.report = true;
        },
    },
});

export const { startLoading, hasError, updates, report } = slice.actions;

export function getUpdates() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get("/updates/last-days");
            dispatch(slice.actions.updates(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getAllUpdates({ page = 1, count = 10, filters = {}, orderField = '', orderSort = '' }) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            let url = `/updates/all?page=${page}&count=${count}`;
            
            if (filters.startDate && !url.includes('startDate')) {
                url += `&startDate=${filters.startDate}`;
            }
            if (filters.endDate && !url.includes('endDate')) {
                url += `&endDate=${filters.endDate}`;
            }

            for (const property in filters) {
                const value = filters[property];
                if (value !== undefined && value !== null && value !== '' && !url.includes(property)) {
                    url += `&${property}=${value}`;
                }
            }

            if (orderField && orderSort) {
                url += `&orderField=${orderField}&orderSort=${orderSort}`;
            }
            const response = await axios.get(url);
            dispatch(slice.actions.allUpdates(response.data));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function createUpdate(data, { page, count, orderField, orderSort }) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.post("/updates/create", data);

            dispatch(
                getAllUpdates({
                    page,
                    count,
                    orderField,
                    orderSort,
                })
            );
            dispatch(getUpdates());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteUpdate(id, { page, count, orderField, orderSort }) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            await axios.delete(`/updates/delete/${id}`);

            dispatch(
                getAllUpdates({
                    page,
                    count,
                    orderField,
                    orderSort,
                })
            );
            dispatch(getUpdates());
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editUpdate(id, updatedData, { page, count, orderField, orderSort }) {
    return async (dispatch) => {
        dispatch(startLoading());
        try {
            await axios.put(`/updates/edit/${id}`, updatedData);

            dispatch(
                getAllUpdates({
                    page,
                    count,
                    orderField,
                    orderSort,
                })
            );
            dispatch(getUpdates());
        } catch (error) {
            dispatch(hasError(error));
        }
    };
}

export default slice.reducer;