import React, { useState } from "react";
import { Form, Input, Button, Alert } from "antd";
import { UserOutlined } from "@ant-design/icons";
import mainLogo from "../../assets/logo/fulllogo_transparent_nobuffer.png";
import { useDispatch,useSelector } from "react-redux";
import { getRecovery } from "../../redux/slices/auth";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  // const { login } = useAuth();
  const dispatch = useDispatch();
  const { loading, error, message } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState(null);

  const { showLogin, version } = props;

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  const handleClose = () => {
    setVisible(false);
    setAlert(null);
  };

  const onFinish = async () => {
    setAlert(null);
    if (email.trim() === "") {
      setVisible(true)
      setAlert("El email es requerido.");
      return;
    }else{
      try{
        setVisible(true)
        dispatch(getRecovery({ email }));
      }catch(e){
        setVisible(true)
      }
    }
  }

  return (
    <Form
      name="normal_login"
      style={{
        width: '100%',
        maxWidth: '400px',
        padding: '0 16px'
      }}
      onFinish={onFinish}
      form={form}
    >
      <div style={{ 
        textAlign: "center", 
        marginBottom: "20px"
      }}>
        <img 
          src={mainLogo} 
          alt="logo" 
          style={{
            width: '100%',
            maxWidth: '200px',
            height: 'auto'
          }}
        />
      </div>
      <h3 style={{color:"white", textAlign:"center"}}>Recuperación de cuenta</h3>
      <p style={{
        color:"white", 
        textAlign:"center",
        fontSize: '0.9rem',
        margin: '16px 0'
      }}>
        Te enviaremos un enlace a tu correo para que puedas cambiar la contraseña.
      </p>
      <Form.Item name="email">
        <Input
          name="email"
          prefix={<UserOutlined />}
          placeholder="Correo electrónico"
          value={email}
          onChange={onChange}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ width: "100%" }}
          loading={loading && true}
        >
          Envíar enlace
        </Button>
      </Form.Item>
      <Form.Item>
        {visible && (
          <Alert 
            message={alert ? alert : message ? message : error} 
            type={alert ? 'error' : (error ? 'error' : 'success')} 
            style={{
              color:"white",
              marginBottom: '16px'
            }} 
            closable 
            afterClose={handleClose} 
          />
        )}
        <a onClick={showLogin} style={{display: 'block', textAlign: 'center'}}>
          Volver a inicio de sesión
        </a>
      </Form.Item>
      <div style={{ 
        textAlign: "center", 
        color:"white",
        fontSize: '0.9rem',
        marginTop: '20px'
      }}>
        {`Powered by Crossover ©${new Date().getFullYear()} Versión ${version}`}
      </div>
    </Form>
  );
};

export default ForgotPassword;
