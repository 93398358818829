import React, { useEffect } from "react";
import { Form, Input, Button, notification } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import mainLogo from "../../assets/logo/fulllogo_transparent_nobuffer.png";
import { useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";

const LoginForm = (props) => {
  const { version, showForgotPassword } = props;
  const { login, error, clearError } = useAuth();
  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Error al iniciar sesión",
        description: error.replace(/^Error:\s*/, ''),
        placement: "topRight",
        style: {
          borderRadius: '8px',
          border: '1px solid #f5222d',
        },
      });

      clearError();
    }
  }, [error, clearError]);

  const onFinish = async (data) => {
    const { email, password } = data;
    await login(email, password);
  };

  return (
    <Form
      name="normal_login"
      style={{
        width: '100%',
        maxWidth: '400px',
        padding: '0 16px'
      }}
      layout="vertical"
      onFinish={onFinish}
    >
      <div style={{ 
        textAlign: "center", 
        marginBottom: "20px"
      }}>
        <img 
          src={mainLogo} 
          alt="logo" 
          style={{
            width: '100%',
            maxWidth: '200px',
            height: 'auto'
          }}
        />
      </div>
      <h3 style={{ color: "white", textAlign: "center" }}>Iniciar Sesión</h3>
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Correo es requerido!' }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder="Correo" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Contraseña es requerida!' }]}
      >
        <Input.Password
          prefix={<LockOutlined />}
          placeholder="Contraseña" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button"
          style={{ width: "100%" }}
          loading={loading && true}
        >
          Ingresar
        </Button>
      </Form.Item>
      <Form.Item>
        <a onClick={() => { showForgotPassword() }}>Recuperar contraseña</a>
      </Form.Item>
      <div style={{ 
        textAlign: "center", 
        color: "white",
        fontSize: '0.9rem',
        marginTop: '20px'
      }}>
        {`Powered by Crossover ©${new Date().getFullYear()} Versión ${version}`}
      </div>
    </Form>
  );
};

export default LoginForm;
