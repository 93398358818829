import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: null,
  successMessage: null,
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
      state.error = null;
      state.successMessage = null;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    emailSentSuccess(state, action) {
      state.loading = false;
      state.successMessage = action.payload;
    },
    resetStatus(state) {
      state.loading = false;
      state.error = null;
      state.successMessage = null;
    },
  },
});

export default emailSlice.reducer;

export function sendSupportEmail(formData, setUploadProgress) {
  return async (dispatch) => {
    dispatch(emailSlice.actions.startLoading());
    try {
      if (!formData.get('nombreUsuario') || !formData.get('correo')) {
        throw new Error("Los datos del usuario son incompletos.");
      }

      const response = await axios.post('/apiNotificaciones/support/send-email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          if (setUploadProgress) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          }
        },
      });

      dispatch(emailSlice.actions.emailSentSuccess('Correo enviado exitosamente'));
      return Promise.resolve(response.data);
    } catch (error) {
      console.error('Error al enviar el correo:', error.message);
      dispatch(emailSlice.actions.hasError(error.message || 'Error desconocido al enviar el correo'));
      return Promise.reject(error);
    }
  };
}