import { createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";

const initialState = {
  loading: false,
  error: false,
  user: null,
  message: ''
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = action.payload.message;
    },
    auth(state, action) {
      state.loading = false;
      state.user = { name: 'Hector', email: 'hamp.martinez@gamil.com' } //action.payload;
    },
    recovery(state, action) {
      state.loading = false;
      state.user = action.payload.user;
      state.message = action.payload.message;
      state.error = false;
    },
  },
});

export default slice.reducer;


export function getAuth({ email, password }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const navigate = useNavigate();
      const url = `/post`;
      const response = await axios.post(url);
      dispatch(slice.actions.auth(response.data));
      navigate('/dashboard');
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRecovery({ email = '' }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/forgotPassword`;
      const response = await axios.post(url, { email });
      dispatch(slice.actions.recovery(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function putChangePassword({ data }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/changepassword`;
      const response = await axios.post(url, { data });
      // console.log(response);
      dispatch(slice.actions.auth(response.data));
    } catch (error) {
      // console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function FirstChangePasswor({ data }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/firstChangePassword`;
      const response = await axios.post(url, { data });

      dispatch(slice.actions.auth(response.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}